@tailwind base;
@tailwind components;
@tailwind utilities;


*::-webkit-scrollbar {
  width: 0;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #000;
}
 
*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.495);
  outline: 1px solid slategrey;
}
